import config from "../../utils/config";
import React from 'react';

const {
    help_page_assets: assets,
    currentSupportEmailAddress,
} = config;


type AnswerType = {
    id: number;
    text: React.ReactNode;
};

type QuestionType = {
    id: number;
    text: string;
    slug: string;
    answer_array: AnswerType[];
};

type SubcategoryType = {
    name: string;
    question_array: QuestionType[]
};


type CategoryType = {
    id: number;
    name: string;
    slug: string;
    user: string;
    image_src: string;
    subcategories: SubcategoryType[];
};

function flatten(categoryDetail: CategoryType) {
    const nested_questions = categoryDetail.subcategories.map(subcategory => subcategory.question_array);
    const flattened_questions: typeof nested_questions[0] = Array.prototype.concat.apply([], nested_questions);
    return {
        id: categoryDetail.id,
        name: categoryDetail.name,
        slug: categoryDetail.slug,
        user: categoryDetail.user,
        image_src: categoryDetail.image_src,
        question_array: flattened_questions,
    };
}


const RAW_DATA_2_LEVEL = {
    "response": {
        "user": [
            {
                "id": 1,
                "name": "マイル獲得関連",
                "slug": "earning_miles",
                "user": "user",
                "image_src": assets.help_page_category_icon_1,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 11,
                                "text": "移動について",
                                "slug": "trips",
                                "answer_array": [
                                    {
                                        "id": 111,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`1回の移動で何マイルたまりますか？`}</span></p>
                                                    <ul>
                                                        <li>{`無料会員の場合、1回の移動で最大15マイルを獲得できます！移動自体の価値は5マイルまたは15マイルで、移動プランによって異なりますが、短い動画を見ることでマイルを2倍に増やすことができます。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><p><span>{`Miles+会員（有料会員）、移動距離が15マイル以下なら、その移動で15マイルを獲得します。移動が15マイルを超える場合、その移動のフルマイル（最大50マイル）を獲得し、50マイルを超える分は10%が加算されます。また、紫色のボタンを選択して移動をブーストすることで、マイルを2倍にすることもできます。`}</span></p></li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <p><span>{`1日に何回まで移動のマイルを獲得できますか？`}</span></p>
                                                    <ul>
                                                        <li>{`現在利用している移動プランによります。 内訳は次の通りです：`}
                                                            <ul>
                                                                <li>{`データ共有無しの無料会員の場合: 1日最大10回の移動獲得が可能`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`データ共有りの無料会員の場合: 1日最大10回の移動獲得が可能`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`Miles+会員（有料会員）の場合: 1日最大20回の移動獲得が可能`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <p><span>{`移動は登録する必要ありますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`いいえ、移動は自動的に記録されます。ただし、Miles の無料会員の場合は、手動で移動マイルを獲得する必要があります。Miles+会員（有料会員）の場合は、移動マイルは自動獲得されます。無料会員の場合は移動マイルを獲得するのに最大7日間の猶予があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`移動がアプリ内に表示されるまで、どれくらい時間がかかりますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`通常、移動が完了してからアプリに移動が表示されるまで30分程度かかります。通常のスマートフォンのご利用には問題ない電波状況であっても、移動を検知するには電波状況が芳しくない、WiFiネットワークの状態が悪いまたは遅い、バッテリー残量が少ないなどの理由で、システムが移動を処理するのにそれ以上かかる場合があり、その結果、移動がアプリに表示されるまでに1～4時間かかることがあります。4時間以上経過しても移動が表示されない場合は、一旦ログアウトし、再度アカウントにログインをお試しください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`無料会員の場合、どのように移動マイルを獲得できますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p>{`移動マイルを獲得するには、移動履歴タブで移動を見つけます。 移動カードにある緑色の「獲得」ボタンを選択します。基本マイルが加算されます。その移動で2倍のマイルを獲得したい場合は、短い動画のボタンの方を選択すると2倍のマイルが加算されるます。獲得が完了するとマイルはお客様のアカウントに反映されます。`}</p>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p>{`もし移動マイルの自動獲得をご希望の場合は「移動履歴」タブ右上からオートクレームパスを購入できます`}</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`移動マイル獲得手続きをしなくても、マイルはたまりますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p>{`無料会員の場合、手動で移動マイル獲得手続きをされなかった場合、移動のマイルは獲得できません。`}</p>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p>{`Miles+会員（有料会員）の場合、移動自体のマイル獲得は自動的に行われますので、アプリから手動で移動自体のマイル獲得手続きをしていただく必要はございません。`}</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`過去の移動の詳細をすべて見ることはできますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`アプリの「移動履歴」タブで過去の移動を見ることができます。ユーザーは7日間の移動履歴が閲覧可能です。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`移動マイルを獲得するための期限はどのくらいですか？`}</span></p>
                                                    <ul>
                                                        <li><p><span>{`無料会員の場合、移動マイルを獲得するのに最大7日間の猶予があります。7日以内に獲得されない場合、その移動マイル分は失効します。Miles+会員（有料会員）の場合は移動マイルは自動的に獲得されます。`}</span></p></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`移動をブーストするにはどうしたらいいですか？`}</span></p>
                                                    <ul>
                                                        <li>{`移動をブーストするには、まず移動履歴ページで、ブーストしたい移動を探してください。見つかったら、移動カードの下部にある「ブースト」ボタンをタップし、マイルが倍増するのをご覧ください。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ブーストはMiles+会員（有料会員）のみ利用可能です`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <p><span>{`移動やマイルが正しく反映されていません。確認すべきことはありますか？`}</span></p>
                                                    <ul>
                                                        <li>{`移動したにも関わらず、その移動やマイルが自動的に記録されていない場合があります。このような事象が起こる原因として、以下が考えられます。`}</li>

                                                        <ul>
                                                            <li>
                                                                <p><span>{`電波が届く場所での移動ではなかった：移動が記録されない最も一般的な理由です。通常のスマートフォンのご利用には問題ない電波状況であってもシステム上移動検知するには移動時の電波が弱かったことが原因として考えられます。また、周回などの移動は、正確なデータが得られず反映されないことがあります。`}</span></p>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`位置情報の設定：`}</span></p>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`【iOS端末の場合】`}</span></p>
                                                                </li>
                                                                <ul>
                                                                    <li>
                                                                        {`(1) iOS端末の設定>設定の中を下の方にスクロール>端末に入っているアプリが並んだ箇所の中からMiles アプリをタップ>位置情報>「常に」にチェック>あわせてその真下にある「正確な位置情報」の項目も「ON」`}
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        {`(2) iOS端末の設定>Miles アプリ>Appのバックグラウンド更新を「ON」`}
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        {`(3) iOS端末の設定>Miles アプリ>モーションとフィットネスの設定が表示されている場合は「ON」`}
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                    <p><span>{`※Appのバックグラウンド更新箇所がグレーアウトしている場合は、iOS端末設定>一般>Appのバックグラウンド更新を「ON」に変更。その後、iOS端末の設定>Miles アプリ>Appのバックグラウンド更新がグレーアウトしておらず「ON」になっているか確認してください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`【Android端末の場合】`}</span></p>
                                                                </li>
                                                                <ul>
                                                                    <li>
                                                                        {`(1) Miles アプリを長押し>アプリ情報>許可>位置情報を「常に許可」を選択`}
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        {`(2) Miles アプリを長押し>アプリ情報>許可>身体活動>「許可する」を選択`}
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        {`(3) Miles アプリを長押し>アプリ情報>モバイルデータとWi-Fi>「バックグラウンドデータ（バックグラウンドでのモバイルデータの使用を有効にする）」を「ON」`}
                                                                    </li>
                                                                </ul>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    {`両OSともに位置情報設定を「常にON」もしくは「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。なお、ご利用端末が低電力モードのときは、アプリのバックグラウンド更新が無効になりますのでご留意ください。`}
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    {`また移動時、常にアプリを開いていただく必要はございませんが、携帯端末は持ち歩いていただく必要がございます。（スマートウォッチや他のヘルスケアアプリなどと連携はできませんのでご理解ください）`}
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <ul>
                                                            <li>
                                                                {`ログイン状態を維持 ：設定がされている状態でアプリにログインした状態を維持してください。意図せずアプリからログアウトしていないか確認してください。`}
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                            <li>
                                                                {`Miles アプリのバージョン：定期的にアプリのアップデートを行い、新機能の追加、問題の修正、体験やパフォーマンスの改善を行っています。可能な限り最高の体験を得るために、アプリをアップデートしてください。`}
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                            <li>
                                                                {`端末OSのバージョン： アプリの機能を確実にサポートする最新のOSバージョンへのアップデートをご検討ください。`}
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                            <li>
                                                            <p><span>{`インターネット接続：Miles アプリはオフラインモード（インターネット接続なし）でもご利用いただけます。オフラインモードでご利用の場合、24時間以内に少なくとも1回はインターネット（Wi-fiまたはデータプラン）に接続しないと、24時間以内にオフラインモードでご利用いただいたすべての移動が記録されない場合があります。*オフラインモードの場合でも位置情報が常にONであることは必須条件です。`}</span></p>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`位置情報サービスをオンにしなくても、Milesアプリを利用できますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`位置情報許可をされないと、Milesアプリは移動を正しく登録できません。 ただし、クイズ、アンケート、ゲーム、シェア、特別コード、お買い物など、他の機能は引き続き利用可能です。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`移動がが2つ以上に分かれているのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`1回の移動が2つ以上の異なる交通手段の場合、または5分間静止していた（移動していなかった）場合、1回の移動が2つ以上の移動に分割されることがあります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 21,
                                "text": "お買い物について",
                                "slug": "shop",
                                "answer_array": [
                                    {
                                        "id": 221,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`お買い物でマイルを獲得するには？`}</span></p>
                                                    <ul>
                                                        <li><span><p>{`マイルバックカテゴリーの特典をご利用いただくと、お買い物でマイルを獲得いただけます。それぞれのマイルバック特典の一番下にある緑色のボタンをタップして、特典を有効にしてください。有効化すると、特典ページに遷移します。この緑色のボタンをタップしたリンクをから利用いただいた場合、マイルが確実に加算されます。なお、プライベートブラウザやVPNをご利用の場合、特典提供元によるご購入の確認ができなくなる可能性がありますので、ご利用にならないようご注意ください。`}</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`ボーナスマイルはお買い物の後どのくらいでもらえますか？`}</span></p>
                                                    <ul>
                                                        <li><span><p>{`マイルバックカテゴリーの特典のリンクからお買い物いただいた場合、加盟店からの確認に最大30日かかることがあります。ご購入の確認が完了すると、マイルは自動的にお客様のアカウントに付与されます。`}</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`特典でもマイルを獲得できますか？`}</span></p>
                                                    <ul>
                                                        <li><span><p>{`お買い物タブにある「特典」カテゴリーに掲載されている特典の場合、ボーナスマイルは加算されません。ボーナスマイルが加算されるのは、「マイルバック」カテゴリーに掲載されている特典のみです。`}</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`「お買い物」タブにあるすべての特典ではボーナスマイルが加算されますか？`}</span></p>
                                                    <ul>
                                                        <li><span><p>{`いいえ、ボーナスマイルが加算されるのは、  「お買い物」タブにある「マイルバック」カテゴリの特典のみです。`}</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`特典に有効期限はありますか？`}</span></p>
                                                    <ul>
                                                        <li><span><p>{`特典には有効期限があります。各特典は午後12時の公開から24時間利用できます。新しい特典が公開されると、以前の特典は利用できなくなります。`}</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`特典はどのくらいの頻度で更新されますか？`}</span></p>
                                                    <ul>
                                                        <li>{`特典は毎日更新され、午後12時に更新されます。特典が更新されると、以前の特典はご利用できなくなります。特典は期間限定ですので、お早めにご利用ください。`}</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 31,
                                "text": "ゲームについて",
                                "slug": "play",
                                "answer_array": [
                                    {
                                        "id": 331,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`ゲームをプレイしてマイルを獲得するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Milesアプリを開いて「ゲーム」タブを選択し、「今すぐプレイ」を選択して、カタログにあるゲーム一覧の中からプレイしたいゲームの「今すぐプレイしましょう」をタップしゲームをインストールすると該当ゲームが「マイゲーム」セクションに追加されるのを確認いただけます。「マイゲーム」セクションに追加されたゲームの「今すぐプレイしましょう」から、さまざまなレベルをプレイすることで、マイルを獲得できます！ ゲームプレイでのマイルを確実に受け取るために、アクティビティの追跡許可設定を有効にしてください。`}</span></p>
                                                        </li>
                                                        <ul>
                                                            <li>
                                                                <p><span>{`*ゲームインストール後、すぐに「マイアプリ」セクションに表示されない場合は、少しだけゲームをプレイした後、「マイアプリ」セクションを確認することをお勧めします。`}</span></p>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`ゲームをプレイしてからどのくらいでマイルがもらえますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ゲームによって条件が異なるため、マイル付与のタイミングはゲームによって異なります。ただし、マイルが付与されるまでには、タスク完了後3日ほどかかる場合があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`プレイできるゲームの数に制限はありますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`プレイできるゲーム数に制限はありません。ただし、すべてのゲームにそれぞれのマイルを獲得するためのタスク目標があります。すべてのタスク達成後にプレイされたゲームに対してはマイルは加算されません。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`ゲームのタスク目標を達成したのにマイルが付与されないのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>{`タスク目標を達成した時のマイル付与はゲームによって異なります。タスク目標を達成したにも関わらず該当マイルが付与されなかった場合は、詳細情報と付与されなかったマイルについて、`} <a href="https://adjoe.zone/legal/en/support_web.html">ゲーム提供元</a> {`にお問い合わせください。 なお、VPNやプライベートブラウズを使用していないことをご確認ください。これらの環境の場合、自動的にマイル付与がされない原因となります。また、そのゲームのプレイを開始する前に、そのゲームが「マイアプリ」セクションに表示されていることをご確認ください。*`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`なお、Miles ゲームポータル（Milesアプリのゲームタブから「今すぐプレイ」をタップした後のページ）からゲームをダウンロードした後、ダウンロードしたゲームアプリを開く前に、別のゲームの広告にアクセスした場合、そのゲームはMilesアプリに登録されません。`}</span></p>
                                                        </li>
                                                        <ul>
                                                            <li>
                                                                <p><span>{`*ゲームインストール後、すぐに「マイアプリ」セクションに表示されない場合は、少しだけゲームをプレイした後、「マイアプリ」セクションを確認することをお勧めします。`}</span></p>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Miles アプリ経由でインストールしたすべてのゲームを確認するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles アプリのゲームタブから「今すぐプレイ」をタップし、遷移先のMiles ゲームページの「マイゲーム」セクションで、Miles アプリ経由でインストールしたすべてのゲームが確認できます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`インストールしたゲームがマイゲームセクションに表示されないのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ゲームインストール後、すぐの場合はまだ反映されていない場合があります。ゲームアプリのダウンロードが完了するまでお待ちください。また、ダウンロードしたアプリが他社のアプリやウェブサイトで追跡できるようアクティビティの追跡を許可してください。許可することで、ゲーム提供元が、あなたがゲームタスクを完了したかどうかを確認するために、あなたを識別できるようになります。Miles ゲームポータル（Milesアプリのゲームタブから「今すぐプレイ」をタップした後のページ）のカタログからゲームアプリを選択した後、Apple Store/Google Play Storeからアプリを入手する必要があることにご留意ください。Apple Store/Google Play Storeからゲームをダウンロードしていない場合、またはMiles ゲームポータルから選択せずにApple Store/Google Play Storeに直接アクセスしてダウンロードした場合は、マイル付与の対象となることができません。`}</span></p>
                                                        </li>
                                                        <ul>
                                                            <li>
                                                                <p><span>{`*ゲームインストール後、すぐに「マイアプリ」セクションに表示されない場合は、少しだけゲームをプレイした後、「マイアプリ」セクションを確認することをお勧めします。`}</span></p>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`どのような種類のゲームをプレイできますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`利用可能なゲームは適宜変更される場合があります。利用可能なゲームは、Miles アプリのゲームタブ >「今すぐプレイ」をタップ > ブラウザに遷移された際に表示されますのでご確認ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`ゲームでマイルを獲得するためのタスク目標はどこから確認できますか？`}</span></p>
                                                    <ul>
                                                        <li>{`(1) Miles アプリのゲームタブをタップ`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`(2) 「今すぐプレイ」をタップ`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`(3) ブラウザに遷移後、「マイアプリ」セクションをタッ`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`(4) 各ゲームの「もっと」という文字をタップすると、そのゲームでのタスク目標とタスクを完了した際に付与されるマイル数をご確認いただけます。`}</li>
                                                        <ul>
                                                            <li>{`なお、初めてプレイする際には、上記(2)の後ブラウザに遷移後、「カタログ」セクションから各ゲームをインストールすると、そのゲームは以後「マイアプリ」セクションに表示されます。ゲームプレイでマイルが付与されるためには「マイアプリ」セクションに該当のゲームが表示されているかご確認ください。`}</li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 41,
                                "text": "クイズについて",
                                "slug": "trivia",
                                "answer_array": [
                                    {
                                        "id": 441,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`クイズに答えてマイルを獲得するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles アプリを開いて回答タブをタップし、クイズセクションの質問に答えることで、マイルを獲得できます。 新しいクイズは毎日提供されます！`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`クイズに挑戦するだけでマイルを獲得できますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`クイズに答えて正解した場合、該当クイズの正解時に付与されるマイル数を獲得できます。 不正解だった場合でも、挑戦したことに対してのマイルが付与されます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`毎日何問クイズに答えることができますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`毎日少なくとも5問のクイズに答えることができます。 連続記録が長いほど、より多くのクイズに答えることができます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`クイズ連続記録とは何ですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`クイズ連続記録は、クイズに参加した連続日数です。 カレンダー日付で、その日の23時59分前にクイズに参加されなかった場合は、クイズ連続記録はリセットされます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`なぜ私のクイズ連続記録がリセットされたのですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`クイズ連続記録は、すべての利用可能なクイズ質問に解答した日数に基づいて計算されます。カレンダー上の日付内にすべてのクイズ質問を完了しなかった場合、翌日にクイズ連続記録がリセットされます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`連続記録フリーズとは何ですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`連続記録フリーズは、クイズの連続記録を1日逃しても、連続記録を維持できる機能です。連続記録フリーズが利用可能で、1日逃した場合、それが適用され、連続記録フリーズが1日延長されます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 51,
                                "text": "アンケートについて",
                                "slug": "survey",
                                "answer_array": [
                                    {
                                        "id": 551,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`アンケート回答でマイルを獲得するにはどうしたらいいですか`}</span></p>
                                                    <ul>
                                                        <li>{`アンケートに回答してマイルを獲得するには`}
                                                            <ul>
                                                                <li>{`アプリの「回答」タブをクリック`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ページをスクロールし参加したいアンケートを見つけます`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`アンケートに回答ください。`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`アンケートでマイルが獲得できなかったのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>{`アンケートの結果、マイルが獲得できない理由はいくつかあります`}
                                                            <ul>
                                                                <li>{`アンケートの参加資格を満たしていない、もしくは該当アンケートの対象外の場合`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`アンケート回答を完了する前にアンケートを終了した場合`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`アンケート回答時にVPN使用が検知された場合`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`アンケートの結果、一部のマイルしか獲得できなかったのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>{`アンケートに回答したにもかかわらず、マイルが一部しか付与されなかった場合は、該当アンケートの要件や対象にマッチしなかったことを意味します。その場合、アンケート参加分として、一部のマイルを獲得することはできます。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`アンケート回答で記載されている全マイルを獲得するには、該当アンケート要件にマッチした上で、アンケートに回答する必要があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`アンケート回答のマイルはいつ付与されますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`アンケートの完了後12時間以内にマイルが付与されます。ほとんどの場合、マイルは即時加算されます。アンケートに回答して12時間以上経過してもマイルが付与されない場合は、アンケートのスクリーンショットを添付の上、アプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告するの手順でカスタマーサポートまでご連絡ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`アンケートの調査対象から外されたのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>{`すべての人がアンケートに参加できるわけではありません。アンケート回答に参加している間に、アンケートが早期に終了することがあります。これをアンケート対象外（アンケート要件マッチせず）と呼びます。これは、あなたの回答や経験が、該当アンケートが求めている対象者と一致しなかった場合に起こります。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`あなたの回答が調査対象者の属性と一致しない場合、あなたの回答はアンケート調査結果から除外され、アンケートは終了します。アンケート対象外を最小限にするためには、スピーディーな回答より、質問に正しく答えるようにしてください。（正しい回答をしても、該当アンケートの求める対称とマッチせず、対象外になることもあることをご了承ください。）`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`アンケートの回答中にエラーが発生したのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>{`アンケートの回答中に、エラーや技術的な問題が発生することがあります。これは、インターネット接続の問題やVPNを使用している場合に発生することがあります。このような場合は後日確認させていただく場合があるため、該当アンケート画面のスクリーンショットを撮り保存しておいてください。またアプリホーム画面左上の人型のアイコンをタップし「お問い合わせ」>「問題を報告する」よりご連絡ください。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`VPNを使用している場合、アンケート回答で得れる全てのマイルが獲得できないことがありますのでご注意ください。VPNを利用してのアンケート回答はお勧めしません。使用しているネットワーク回線の変更をご検討ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 61,
                                "text": "特別コードについて",
                                "slug": "promo_code",
                                "answer_array": [
                                    {
                                        "id": 661,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`特別コードの入力方法は？`}</span></p>
                                                    <ul>
                                                        <li>{`特別コードの入力方法は以下の通りです：ログイン中のアプリのホーム > 左上の人型のアイコン > 特別コードをタップ >指定された特別コードを入力します。`}</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 81,
                                "text": "ブースト",
                                "slug": "boost",
                                "answer_array": [
                                    {
                                        "id": 881,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`ブーストを開始するにはどうすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ホームタブの右上にある雷のアイコンを選択し、「ブーストを開始」を選んでから「獲得しよう」を選択します。タイマーが開始されると、次の30分間の獲得マイルが2倍になります！`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`ブーストはどのくらい続きますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ブーストを開始した時点から、30分間マイル獲得が2倍になります！`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`ブースト時にすべての獲得機能が含まれますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ほとんどの機能が含まれますが、すべてではありません。「マイルバック」の特典や、友達紹介で獲得したマイルは含まれません。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                ],
            },
            {
                "id": 2,
                "name": "マイル交換関連",
                "slug": "redeeming_miles",
                "user": "user",
                "image_src": assets.help_page_category_icon_2,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 12,
                                "text": "ギフトカード関連",
                                "slug": "gift_cards",
                                "answer_array": [
                                    {
                                        "id": 112,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`ギフトカードの交換に制限があるのはなぜですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ギフトカード特典は人気が高く、ご利用される方が多いため、より多くの方にギフトカード特典の交換機会を提供するために、月間の交換制限を設けています。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`マネーやギフトカードにはどのくらいの頻度で交換できますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Milesの無料会員では週に1回、Miles+会員（有料会員）では週に2回交換可能です。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`小売店がギフトカードを受け付けない場合はどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles でマイルで交換して得たギフトカード特典をご利用されたい小売店が受け付けない場合は、小売店と直接解決していただく必要があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`なお、それぞれのギフトカードの注意事項等を試しても解消しない場合は、小売店のカスタマーサポートに問い合わせてください。より詳しい利用のためのヒントや手順が記載されている場合があります。すべての制限や例外はMilesで該当ギフトカードを交換した際に記載されており、ご利用されたい小売店によって異なる場合があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`ギフトカードに有効期限はありますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`各ギフトカードブランドによって有効期限は異なります。なお、Miles アプリ上では交換されたギフトカードのデータは60日間のみの保持のため、Miles アプリで交換後、すみやかにギフトカードやギフトカードコードを保存し、ご自身でご利用いただけるようにしてください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 22,
                                "text": "PayPalについて",
                                "slug": "paypal",
                                "answer_array": [
                                    {
                                        "id": 222,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`マイルをPayPal マネーに交換するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`マイルをPayPal マネーに交換するには、Miles アプリのホームタブにて「マイルを交換しよう」のセクションで「マネー」を選択してください。すべてのPayPal 交換オプションがこちらに表示されます。交換後、PayPal アカウント連携が完了すると、連携したPayPal アカウントに送金されます。この手続きには最大3日かかる場合があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`なぜPayPalに交換したのに入金されないのですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`PayPalアカウントとMiles の連携手続き完了後、お客様のPayPalアカウントに入金が完了するまで最大3日かかる場合がございます。3日以上かかっても入金されない場合は、アプリのホーム > 左上の人型のアイコン >交換された特典 >該当のPayPal交換履歴をタップしステータスをご確認ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 32,
                                "text": "抽選について",
                                "slug": "raffle",
                                "answer_array": [
                                    {
                                        "id": 332,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`抽選に参加するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles アプリのホームタブを開き、「マイルを交換しよう」までスクロールして、「抽選」アイコンをタップすると、抽選に参加することができます。参加したい抽選を選択したら、スライドバーを使って交換したい抽選エントリー数を選択することができます。ご希望のエントリー数を設定したら、右下の緑色のボタンをクリックして参加手続きを完了してください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`抽選の賞品を獲得するには？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`抽選参加後の状況確認と当選した場合賞品を獲得するには、アプリのホーム > 左上の人型のアイコン >交換された特典 >抽選タブを選択してください。`}</span></p>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`注：当選発表から2週間以内に賞品をお獲得してください。この期間内に獲得されなかった賞品は無効となり、獲得できなくなります。`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 42,
                                "text": "寄付について",
                                "slug": "donations",
                                "answer_array": [
                                    {
                                        "id": 442,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`寄付はギフトカードと同じ価値がありますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`私たちは、需要の高いギフトカードを最大限に交換して得られるようにすることを優先しています。そのため、ギフトカードが最も良いレートを得られるようにしていますが、それでも慈善団体への寄付を行う機会はあります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 52,
                                "text": "その他",
                                "slug": "other",
                                "answer_array": [
                                    {
                                        "id": 552,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`すべてのマイル交換先はどこにありますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`すべてのマイル交換先はアプリのホームタブの「マイルを交換しよう」のセクションにあります。マネー、ギフトカード、抽選、寄付の交換先があります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{` 一度交換した特典はどうなりますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`一度交換した特典（マネー/ギフトカード/抽選/寄付）は、アプリのホーム > 左上の人型のアイコン > 「交換された特典」で確認可能です。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`どのような特典がありますか？`}</span></p>
                                                    <ul>
                                                        <li>{`マイルの交換先は以下の通りです：`}
                                                            <ul>
                                                                <li>{`ギフトカード`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`マネー`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`抽選`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`寄付`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`すべての移動やボーナスで獲得したマイルは、アプリ内で提供している特典への交換にご利用いただけます。特典への交換は、アプリのホームタブの「マイルを交換しよう」のそれぞれの特典アイコンをタップ後、それぞれの特典カードをタップして進んでください。手続き時に「OK」ボタンをタップし交換を完了できます。なお、Miles+会員（有料会員）の場合、同じ特典を交換するのに必要なマイル数は、無料会員の場合よりも少なくなります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ],
                    },
                ],
            },
            {
                "id": 3,
                "name": "お友達紹介",
                "slug": "referrals",
                "user": "user",
                "image_src": assets.help_page_category_icon_3,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 13,
                                "text": "お友達紹介ボーナスについて",
                                "slug": "claiming_referrals",
                                "answer_array": [
                                    {
                                        "id": 113,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`お友達紹介キャンペーンでのボーナスマイルはいつ受け取れますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`お友達紹介特典のマイルは、被紹介者がMiles 新規登録後、48時間以内に紹介コードをアカウントに適用させ、且つ新規登録後7日以内に750マイルを獲得したことが確認された場合付与されます、紹介者は被紹介者のアカウント開設から7日後に自動的に紹介ボーナスを受け取ります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ]
                    },
                ],
            },
            {
                "id": 4,
                "name": "アカウント設定",
                "slug": "profile-and-account-settings",
                "user": "user",
                "image_src": assets.help_page_category_icon_4,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 14,
                                "text": "アカウント設定",
                                "slug": "account-set-up",
                                "answer_array": [
                                    {
                                        "id": 114,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`アプリのインストールについて`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`App StoreまたはGoogle Play Storeで、Milesアプリを検索してダウンロードしてください。（アプリ提供デベロッパ：ConnectIQ Labs）また、Milesホームページを訪れ、電話番号入力欄に電話番号を入力することでも、ダウンロードリンクを受け取ることができます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Miles のアカウント登録時のプランにはどのようなものがありますか？`}</span></p>
                                                    <ul>
                                                        <li>{`3種類のプランがあります：`}
                                                            <ul>
                                                                <li>Miles
                                                                    <ul>
                                                                        <li>{`広告あり、データ共有なし、1日10回の移動、1回の移動毎に5マイル獲得、週1回特典への交換可能、無料会員`}</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>Miles
                                                                    <ul>
                                                                        <li>{`広告あり、データ共有あり、1日10回の移動、1回の移動毎に15マイル獲得、週1回特典への交換可能、無料会員`}</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li> Miles+
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{`広告なし、データ共有なし、1日20回の移動、移動距離15マイルを超える移動でより多くのマイル獲得、週2回特典への交換可能、ダイヤ会員ステータス保証、非アクティブによるマイルの有効期限適用除外、有料会員`}</span></p>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`アカウントの作成について`}</span></p>
                                                    <ul>
                                                        <li>{`以下のいずれかの方法で、Miles アプリよりアカウントを作成してください：`}
                                                            <ul>
                                                                <li>{`Facebook - 登録ページで「Facebookでログイン」をタップし、簡単なステップ・バイ・ステップのワークフローに従ってください。なお、Miles がお客様のFacebookアカウントに何かを投稿することはありませんのでご安心ください。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`Eメール - 登録ページで「登録」をタップし、有効なEメールアドレスを追加し、有効なパスワードを作成し、お名前を入力してください。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`Apple ID - 登録ページで「Apple IDでログイン」をタップし、関連する簡単なステップ・バイ・ステップのワークフローに従ってください。このオプションでは、メールアドレスを隠してサインアップするオプションが表示されます。このオプションを選択すると、アカウントに関連付けられた電子メールは[ランダムな文字/数字]@appleid.privaterelay.comとして表示されますが、電子メールによる連絡はApple IDに関連付けられた電子メールアドレスに送信されます。`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`アプリの設定とおすすめ`}</span></p>
                                                    <ul>
                                                        <li>{`アカウント登録が完了したら、以下の設定をすることでMiles を快適にご利用いただけます。`}
                                                            <ul>
                                                                <li>{`位置情報サービス:`}
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{`【iOS端末の場合】`}</span></p>
                                                                            <ul>
                                                                                <li>{`(1) iOS端末の設定>設定の中を下の方にスクロール>端末に入っているアプリが並んだ箇所の中からMiles アプリをタップ>位置情報>「常に」にチェック>あわせてその真下にある「正確な位置情報」の項目も「ON」`}</li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>{`(2) iOS端末の設定>Miles アプリ>Appのバックグラウンド更新を「ON」(3) iOS端末の設定>Miles アプリ>モーションとフィットネスの設定が表示されている場合は「ON」`}</li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>{`※Appのバックグラウンド更新箇所がグレーアウトしている場合は、iOS端末設定>一般>Appのバックグラウンド更新を「ON」に変更。その後、iOS端末の設定>Miles アプリ>Appのバックグラウンド更新がグレーアウトしておらず「ON」になっているか確認してください。`}</li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                        
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{`【Android端末の場合】`}</span></p>
                                                                            <ul>
                                                                                <li>{`(1) Miles アプリを長押し>アプリ情報>許可>位置情報を「常に許可」を選択`}</li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>{`(2) Miles アプリを長押し>アプリ情報>許可>身体活動>「許可する」を選択`}</li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>{`(3) Miles アプリを長押し>アプリ情報>モバイルデータとWi-Fi>「バックグラウンドデータ（バックグラウンドでのモバイルデータの使用を有効にする）」を「ON」`}</li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                        
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{`両OSともに位置情報設定を「常にON」もしくは「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。なお、ご利用端末が低電力モードのときは、アプリのバックグラウンド更新が無効になりますのでご留意ください。`}</span></p>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                        
                                                                <li>{`通知の設定：`}
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{` 新機能、ボーナスマイル、新しい特典などに関する新着情報を見逃さないために、通知設定を「オン」にすることをお勧めします。`}</span></p>
                                                                        </li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{`【iOS端末の場合】`}</span></p>
                                                                            <ul>
                                                                                <li>{`通知設定を更新するには、iOS端末の設定>通知>Miles アプリ>通知を許可を「ON」`}</li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                        
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>{`【Android端末の場合】`}</span></p>
                                                                            <ul>
                                                                                <li>{`通知設定を更新するには、Miles アプリを長押し>アプリ情報>通知>通知を許可を「ON」`}</li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`アプリを開く：アプリを定期的に開き、バッテリーの最適化やその他の理由によってアプリが意図せずに閉じていないか確認してください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`ログイン状態を維持する：意図せずにアプリからログアウトしていないか確認してください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`Wi-Fi：アプリが機能するために必須ではありませんが、Wi-Fiを有効にすることで、パフォーマンス精度をさらに向上させ、バッテリーの消費を抑えることができます。`}</span></p>
                                                                    </li>
                                                                </ul>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 24,
                                "text": "Miles+（有料サブスクリプション）について",
                                "slug": "miles_plus",
                                "answer_array": [
                                    {
                                        "id": 224,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p>
                                                        <span>{`有料会員と無料会員の違いは何ですか？`}</span>
                                                    </p>
                                                    <ul>
                                                        <li>{`無料会員では、1日10回までの移動を手動で獲得が必要です。なお、無料会員は1週間に1回までの特典交換制限があります。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles+会員（有料会員）では、移動の自動獲得が可能で、1日最大20回の移動が獲得可能です。週に2回ギフトカードへの交換が可能で、ダイヤモンド会員に自動的にアップグレードされます。また、Miles+会員（有料会員）は広告なしのプラットフォームがあり、非アクティブによるマイルの失効やデータ共有が免除されます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Miles+会員（有料会員）にはどのような特典がありますか？`}</span></p>
                                                    <ul>
                                                        <li>{`Miles+には多くのベネフィットがあります。以下が含まれます：`}
                                                            <ul>
                                                                <li>{`ダイヤモンド会員ステータスの保証`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`移動マイル自動獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`1日に最大20回の移動`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`週2回のギフトカード交換`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`プレミアムギフトカードへのアクセス`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`広告なし`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`データ共有なし`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`非アクティブによるマイル有効期限切れなし`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Miles+会員（有料会員）のサブスクリプションは自動的に更新されますか？`}</span></p>
                                                    <ul>
                                                        <li>{`Miles側が別途同意しない限り、サブスクリプションは会員様側でキャンセルされるまで、月単位もしくは年単位（該当する場合）で自動的に更新されます。以下で、自動更新されるサブスクリプションについての情報をご覧いただけます。`}
                                                            <ul>
                                                                <li>App Store: <a href="https://support.apple.com/ja-jp/HT202039">https://support.apple.com/ja-jp/HT202039</a></li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Google Play Store: <a href="https://support.google.com/googleplay/answer/2476088?hl=ja">https://support.google.com/googleplay/answer/2476088?hl=ja</a></span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Miles+会員（有料会員）のサブスクリプションに加入していましたが、アカウントを削除することにした場合、加入は自動的に終了しますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`いいえ。アカウント削除ではサブスクリプション（定期購入）はキャンセルされませんので、App Store/Google Playストアからご自身でキャンセルしてください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Miles+会員（有料会員）の購読をキャンセル/変更するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`App Store/Google Playストア内のサブスクリプション（定期購入）から行うことができます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`サブスクリプション（定期購入）を使用しなかった場合、返金されますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`アカウントをいつでもキャンセルするオプションがありますが、適用される法律で禁止されていない限り、サブスクリプションの未使用期間やその他の状況に関して返金は行いません。キャンセル後も、現在の有料期間が終了するまでサブスクリプションは有効です。例えば、年間サブスクリプションを購入して2ヶ月後にキャンセルした場合、残りの期間については返金されませんが、既に支払った残りの10ヶ月間はサブスクリプションの特典を引き続き享受できます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 34,
                                "text": "ステータスについて",
                                "slug": "tiers",
                                "answer_array": [
                                    {
                                        "id": 334,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`次のステータスに上がる方法は？`}</span></p>
                                                    <ul>
                                                        <li>{`獲得マイル数に応じて、次のステータスにレベルアップできます：`}
                                                            <ul>
                                                                <li>{`シルバー会員：0マイル獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ゴールド会員：500マイル獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`プラチナ会員：5,000マイル獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>{`ダイヤ会員：10,000マイル獲得`}</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`各ステータスの特典は何ですか？`}</span></p>
                                                    <ul>
                                                        <li>{`月間ボーナスマイル：`}
                                                            <ul>
                                                                <li>{`シルバー： 毎月、先月獲得マイルの1%をボーナスとして獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ゴールド： 毎月、先月獲得マイルの2%をボーナスとして獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`プラチナ： 毎月、先月獲得マイルの3%をボーナスとして獲得`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ダイヤ： 毎月、先月獲得マイルの4%をボーナスとして獲得`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`毎月付与の無料オートクレーム：`}
                                                            <ul>
                                                                <li>{`ゴールド: 移動マイルを自動で獲得可能。2日間分無料オートクレーム`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`プラチナ: 移動マイルを自動で獲得可能。4日間分無料オートクレーム`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ダイヤ: 移動マイルを自動で獲得可能。6日間分無料オートクレーム`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    









                                                    <ul>
                                                        <li>{`毎月付与の無料フリーズ：`}
                                                            <ul>
                                                                <li>{`ゴールド: クイズ連続記録を守ります。月に1個分の無料フリーズ`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`プラチナ: クイズ連続記録を守ります。月に2個分の無料フリーズ`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ダイヤ: クイズ連続記録を守ります。月に3個分の無料フリーズ`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`毎月付与の無料ブースト：`}
                                                            <ul>
                                                                <li>{`ゴールド: アプリ内の獲得マイルを2倍に。月に1個分の無料ブースト`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`プラチナ:アプリ内の獲得マイルを2倍に。月に2個分の無料ブースト`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ダイヤ: アプリ内の獲得マイルを2倍に。月に3個分の無料ブースト`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`ギフトカード：`}
                                                            <ul>
                                                                <li>{`プラチナ： プレミアムギフトカードへのアクセス`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`ダイヤ： プレミアムギフトカードへのアクセス`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 44,
                                "text": "Eメールやアカウント情報の更新",
                                "slug": "email_update",
                                "answer_array": [
                                    {
                                        "id": 444,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`アカウントの登録Eメールアドレスを変更するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`ログイン中のアプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告する>アカウント設定>「Eメールやアカウント情報の更新」のカテゴリから変更希望のメールアドレスを添えてご連絡ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 64,
                                "text": "アカウント削除",
                                "slug": "delete_account",
                                "answer_array": [
                                    {
                                        "id": 664,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`アカウント削除はどのようにすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>{`アカウント削除依頼は、アプリ内から申請ができます。`}
                                                            <ul>
                                                                <li>{`アカウント削除希望のアカウントでMiles アプリにログイン>画面左上の人型のアイコン>設定>プライバシー>アカウント削除`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`アカウント削除画面が表示されましたら、確認画面の内容をご確認いただき、アカウト削除希望の場合は『アカウントを削除しますか？「続ける」』をタップ>アカウント削除の理由を選択>ご登録メールアドレス宛に送られた認証コードを入力>確認画面の内容をご確認いただき「アカウント削除」をタップ>『アカウントを削除してよろしいですか？「OK」「キャンセル」』の「OK」をタップ>『削除依頼が完了しました。アカウントは7日後に削除されます「OK」』の完了画面が出ますので「OK」をタップしますとアカウント削除依頼は完了し、アプリからログアウトした状態になります。アカウント削除依頼から7日以内にアカウントは削除されます。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`なお、申請後はすぐにアカウントへのアクセスができなくなるため、ログインはできません。また、アカウント削除依頼後にアカウント復旧はできません。再度ご利用を希望の場合は、アプリから再度ご登録ください。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`アカウント削除希望のアカウントでMiles アプリにログインしているかどうかの確認は、以下の手順でご確認ください。`}</li>
                                                                <ul>
                                                                    <li>{`ログイン中のアプリのホーム > 左上の人型のアイコン > もっと見る をタップしますと、現在アプリにログイン中のメールアドレスをご確認いただけます。`}</li>
                                                                </ul>
                                                            </ul>
                                                            <ul>
                                                                <li>{`本FAQの手順でアカウント削除申請ができない場合は、Miles アプリのバージョンが古いことが考えられますのでMiles アプリをアップデートして再度お試しください。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`なお、上記手順でアプリからのアカウント削除申請後、アプリからのアカウント削除申請依頼が完了しているかどうかのご確認は、アカウント削除されたメールアドレスでアプリにログインを試みますと「Sign Up こちらのメールアドレスでログインはできません。別のメールアドレスをご利用ください」と表示されますので、そちらをご確認ください`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`もしも上記手順でアプリ内からのアカウント削除申請手続きができない場合は、アカウント削除希望のアカウントでMiles アプリにログインいただき、以下の方法でご連絡いただきますようお願い申し上げます。`}</li>
                                                                <ul>
                                                                    <li>{`アプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告する > アカウント設定 >「アカウント削除」を選択し、「アカウント削除希望/ アプリからアカウント削除できなかった」旨を記載の上、ご連絡ください。※問い合わせ本文内に会員IDやご登録メールアドレスの記載は不要です。上記手順でお問い合わせいただくと、お客様がログイン中のアプリのご登録メールアドレスがカスタマーサポートで確認できるようになっております。`}</li>
                                                                </ul>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 74,
                                "text": "その他",
                                "slug": "other",
                                "answer_array": [
                                    {
                                        "id": 774,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`獲得・交換したマイルの履歴を見ることはできますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`獲得マイル（ボーナスマイルを含む）と交換マイルの履歴を月ごとに確認するには、マイルアプリのホームタブをクリックし、"マイルバンク "までスクロールしてください。「すべて表示」を選択すると、当月と1ヶ月前の履歴をご覧いただけます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`マイルの有効期限はありますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Milesアプリは無料で利用でき、アカウントがアクティブである限り、マイルの有効期限はありません。ただし、アカウントが非アクティブの場合マイル残高はゼロにリセットされます。30日間以上マイルを獲得または交換していない場合は非アクティブとなります。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`マイルをためるためにデータプランは必要ですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Milesは、インターネットに接続されていないオフライン手段でも動作します。オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`プロフィールページに自分の写真を追加するにはどうすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles アプリのホーム> 左上の人型のアイコン>もっと見る>アイコンの下にある編集マーク>アイコンの下にある＋マークでアバターを選択したり、Facebookのプロフィール写真を使用できます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`なお、プロフィールページに設定されたFacebookのプロフィール写真もしくはアバターは他のユーザーからは閲覧できるものではないため、ご安心ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`アプリが動作しているかどうかを確認するにはどうすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`アプリが正常に機能しない場合、理由は様々ですが、以下の条件を満たしていれば、アプリが正常に機能する可能性が高くなります。`}</span></p>
                                                        </li>
                                                        <ul>
                                                            <li>{`位置情報サービス：`}
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`【iOS端末の場合】`}</span></p>
                                                                        <ul>
                                                                            <li>{`(1) iOS端末の設定>設定の中を下の方にスクロール>端末に入っているアプリが並んだ箇所の中からMiles アプリをタップ>位置情報>「常に」にチェック>あわせてその真下にある「正確な位置情報」の項目も「ON」`}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>{`(2) iOS端末の設定>Miles アプリ>Appのバックグラウンド更新を「ON」(3) iOS端末の設定>Miles アプリ>モーションとフィットネスの設定が表示されている場合は「ON」`}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>{`※Appのバックグラウンド更新箇所がグレーアウトしている場合は、iOS端末設定>一般>Appのバックグラウンド更新を「ON」に変更。その後、iOS端末の設定>Miles アプリ>Appのバックグラウンド更新がグレーアウトしておらず「ON」になっているか確認してください。`}</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`【Android端末の場合】`}</span></p>
                                                                        <ul>
                                                                            <li>{`(1) Miles アプリを長押し>アプリ情報>許可>位置情報を「常に許可」を選択`}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>{`(2) Miles アプリを長押し>アプリ情報>許可>身体活動>「許可する」を選択`}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>{`(3) Miles アプリを長押し>アプリ情報>モバイルデータとWi-Fi>「バックグラウンドデータ（バックグラウンドでのモバイルデータの使用を有効にする）」を「ON」`}</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`両OSともに位置情報設定を「常にON」もしくは「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。なお、ご利用端末が低電力モードのときは、アプリのバックグラウンド更新が無効になりますのでご留意ください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`アプリを開く：アプリを定期的に開き、バッテリーの最適化やその他の理由によってアプリが意図せずに閉じていないか確認してください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`ログイン状態を維持する：意図せずにアプリからログアウトしていないか確認してください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。`}</span></p>
                                                                    </li>
                                                                </ul>
                                        
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。`}</span></p>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        <p><span>{`インターネット接続：Miles アプリはオフラインモード（インターネット接続なし）でもご利用いただけます。オフラインモードでご利用の場合、24時間以内に少なくとも1回はインターネット（Wi-fiまたはデータプラン）に接続しないと、24時間以内にオフラインモードでご利用いただいたすべての移動が記録されない場合があります。*オフラインモードの場合でも位置情報が常にONであることは必須条件です。`}</span></p>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li>{`通知設定の方法を教えてください。`}
                                                    <ul>
                                                        <li>
                                                            <p><span>{` 新機能、ボーナスマイル、新しい特典などに関する新着情報を見逃さないために、通知設定を「オン」にすることをお勧めします。`}</span></p>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <ul>
                                                            <li>
                                                                <p><span>{`【iOS端末の場合】`}</span></p>
                                                                <ul>
                                                                    <li>{`通知設定を更新するには、iOS端末の設定>通知>Miles アプリ>通知を許可を「ON」`}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                            <li>
                                                                <p><span>{`【Android端末の場合】`}</span></p>
                                                                <ul>
                                                                    <li>{`通知設定を更新するには、Miles アプリを長押し>アプリ情報>通知>通知を許可を「ON」`}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`位置情報サービスの設定はどうすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`【iOS端末の場合】`}</span></p>
                                                        </li>
                                                        <ul>
                                                            <li>{`(1) iOS端末の設定>設定の中を下の方にスクロール>端末に入っているアプリが並んだ箇所の中からMiles アプリをタップ>位置情報>「常に」にチェック>あわせてその真下にある「正確な位置情報」の項目も「ON」`}</li>
                                                        </ul>
                                                        <ul>
                                                            <li>{`(2) iOS端末の設定>Miles アプリ>Appのバックグラウンド更新を「ON」`}</li>
                                                        </ul>
                                                        <ul>
                                                            <li>{`(3) iOS端末の設定>Miles アプリ>モーションとフィットネスの設定が表示されている場合は「ON」`}</li>
                                                        </ul>
                                                        <ul>
                                                            <li>{`※Appのバックグラウンド更新箇所がグレーアウトしている場合は、iOS端末設定>一般>Appのバックグラウンド更新を「ON」に変更。その後、iOS端末の設定>Miles アプリ>Appのバックグラウンド更新がグレーアウトしておらず「ON」になっているか確認してください。`}</li>
                                                        </ul>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`【Android端末の場合】`}</span></p>
                                                        </li>
                                                        <ul>
                                                            <li>{`(1) Miles アプリを長押し>アプリ情報>許可>位置情報を「常に許可」を選択`}</li>
                                                        </ul>
                                                        <ul>
                                                            <li>{`(2) Miles アプリを長押し>アプリ情報>許可>身体活動>「許可する」を選択`}</li>
                                                        </ul>
                                                        <ul>
                                                            <li>{`(3) Miles アプリを長押し>アプリ情報>モバイルデータとWi-Fi>「バックグラウンドデータ（バックグラウンドでのモバイルデータの使用を有効にする）」を「ON」`}</li>
                                                        </ul>
                                                    </ul>
                                                    <ul>
                                                        <li>{`両OSともに位置情報設定を「常にON」もしくは「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。なお、ご利用端末が低電力モードのときは、アプリのバックグラウンド更新が無効になりますのでご留意ください。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><p><span>{`また移動時、常にアプリを開いていただく必要はございませんが、携帯端末は持ち歩いていただく必要がございます。（スマートウォッチや他のヘルスケアアプリなどと連携はできませんのでご理解ください）`}</span></p></li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`パスワードを再設定するにはどうすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>{`メールアドレスを使って再ログインする場合は、「パスワードをお忘れですか？」をタップしてください。以前にサインアップしたときに使用したEメールアドレスを入力するよう求められ、このメールアドレスにパスワードをリセットするためのリンクを含む認証メールが送信されます。リンクをタップして、パスワードをリセットしてください。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`パスワード再設定メールが届かない場合は、迷惑メールフォルダ等受信トレイ以外のメールフォルダもご確認ください。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`アプリからログアウトするにはどうすればいいですか？`}</span></p>
                                                    <ul>
                                                        <li>{`ログアウトするには、Miles アプリのホーム> 左上の人型のアイコン>設定>ログアウトしてください。`}</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ]
                    },
                ],
            },
            {
                "id": 5,
                "name": "その他",
                "slug": "other",
                "user": "user",
                "image_src": assets.help_page_category_icon_5,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 15,
                                "text": "複数端末利用について",
                                "slug": "multiple_devices",
                                "answer_array": [
                                    {
                                        "id": 115,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`複数の端末でアプリを利用できますか？`}</span></p>
                                                    <ul>
                                                        <li>{`はい。ただし、マイルが加算されるのは、サインインしている1つのデバイスのみです。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`注：1つのデバイスにて複数のアカウントを登録することはできません。この場合、すべてのアカウントが接続され、利用規約に違反する不正なアカウントとして検知されます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 25,
                                "text": "不正利用について",
                                "slug": "fraud",
                                "answer_array": [
                                    {
                                        "id": 225,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`なぜ私のアカウントが削除されたのですか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles リワードプログラムは、利用規約に記載されたガイドラインに従って当社のプラットフォームを利用するユーザーに報酬を還元するものです。複数のアカウントを利用する、抜け穴を利用する、何らかの方法でシステムを操作するなど、ユーザーが当プラットフォームを悪用しているとMiles が判断した場合、Miles はそのユーザーを削除する権利を有します。その場合、Miles は当該ユーザーを削除する権利を有し、当該アカウントおよび当該ユーザーから派生する可能性のある新規アカウントを削除するものとします。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 35,
                                "text": "バッテリー消費について",
                                "slug": "battery_consumption",
                                "answer_array": [
                                    {
                                        "id": 335,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`Miles アプリはどのくらいバッテリーを消費しますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles は停止しているときはバッテリーを消費しません。バッテリーの消費は、移動中にわずかに増える程度で、平均的な1日の移動ではバッテリー残量の1%と推定されます。Wi-Fiが常時オンになっていることを確認することで、バッテリー寿命を延ばすことができます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                          
                            {
                                "id": 55,
                                "text": "データプライバシー",
                                "slug": "data_privacy",
                                "answer_array": [
                                    {
                                        "id": 555,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>{`アプリはどのくらいのデータを使用しますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`Miles アプリが使用するデータ量はごくわずかです。データ使用量は、移動の回数や各移動の距離によって異なります。アプリが使用するデータ量は平均的なデータプランの1～2％程度と思われます。データ使用量をさらに減らすために、Wi-Fiのご利用をお勧めします。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`利用規約やプライバシーポリシーはアプリのどこに記載されていますか？`}</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`利用規約とプライバシーポリシーは、アプリホーム画面>左上の人型のアイコン>「アプリについて」に記載しております。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`データ共有を停止するにはどうすればよいですか？`}</span></p>
                                                    <ul>
                                                        <li>{`現在、データ共有有りの無料プランをご利用中のお客様は、Miles+会員（有料会員）にアップグレードするか、データ共有無しの無料プランにダウングレードすることができます。アプリホーム画面>左上の人型のアイコン>「移動プラン」で変更手続きを行うことができます。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>{`現在Miles+会員（有料会員）またはデータを共有無しの無料プランをご利用中のお客様は、お客様のアカウントはすでにデータ共有を停止しています。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>{`Milesアプリを使用することでどのような情報が収集されるのですか？`}</span></p>
                                                    <ul>
                                                        <li>{`利用者が当社に提供する情報`}</li>
                                                        <ul>
                                                            <li>{`当社は、利用者が当社に直接提供する情報を収集します。 例えば、当社は次のようなときに情報を収集します：利用者がアカウントを作成したとき、本サービスのインタラクティブ機能に参加したとき、フォームを記入したとき、プロフィール写真をアップロードしたとき、コンテストやプロモーションに参加したとき、特典を交換したとき、カスタマーサポートに連絡したとき、またはその他の方法で当社と連絡を取ったとき、等。当社が収集する可能性のある情報には、利用者の氏名、電話番号、Eメールアドレス、プロフィール写真、および利用者ご自身が提供されるその他の情報が含まれます。`}</li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`本サービスの利用に関する情報`}</span></p>
                                                    <ul>
                                                        <li>{`利用情報`}</li>
                                                        <ul>
                                                            <li>{`当社は、利用者の本サービスの利用に関する情報を収集します。当社は、利用者の位置を特定するために、IPアドレス、GPS、およびその他のセンサーを含む、様々な技術を使用しており、それによって例えば近くにあるデバイス、Wi-Fiアクセスポイント、基地局に関する情報を収集することがあります。また、コンパス、加速度センサー、ジャイロセンサーなど、利用者のデバイスに搭載されている他のセンサーから情報を収集することもあります。`}</li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`自動的に収集される情報`}</span></p>
                                        
                                        
                                                    <ul>
                                                        <li>{`利用者が本サービスにアクセスしたり、本サービスを利用したりすると、当社は以下のような情報を自動的に収集します。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`ログ情報： 当社は、利用者が使用しているOSのバージョン、アプリのバージョン、デバイスID、およびその他のアプリ内またはオンラインアクティビティ（利用者パス、アクセス時間、閲覧ページなど）を含む、本サービスの利用に関するログ情報を収集します。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`デバイス情報： 当社は、利用者が当社のサービスにアクセスするために使用するコンピュータまたはモバイル機器に関する情報を収集します。これには、ハードウェアモデル、オペレーティングシステムおよびバージョン、機器固有の識別子、およびモバイルネットワーク情報が含まれます。`}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>{`位置情報：上述のとおり、利用者のデバイスの許可設定に従って、当社は、デバイスから取得された移動データ（加速度など）を含む、デバイスの正確な位置および移動に関する情報を収集します。詳細については、以下の「利用者の選択」をご覧ください。`}</li>
                                                    </ul>
                                        
                                                    <ul>
                                                        <li><p><span>{`クッキーおよびその他のトラッキング技術によって収集される情報：多くのオンラインサービスやモバイルアプリケーションと同様に、当社は、クッキーやウェブビーコン、ウェブストレージ、広告用のユニークな識別子などの技術を使用して、利用者の活動、ブラウザ、およびデバイスに関する情報を収集することがあります。クッキーとは、利用者のハードドライブまたはデバイスのメモリに保存される小さなデータファイルのことで、当社のサービスおよび利用者の体験を向上させ、当社のサービスのどの機能に人気があるか等を確認し、訪問回数をカウントするのに役立ちます。
                                                            ウェブビーコンとは、当社のサービスまたはEメールで使用される電子画像であり、クッキーの配信、訪問回数のカウント、利用状況やキャンペーンの効果の把握に役立ちます。クッキーに関する詳細およびクッキーを無効にする方法については、以下の「利用者の選択」をご覧ください。`}</span></p></li>
                                                    </ul>
                                        
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`当社が他の情報源から収集する情報`}</span></p>
                                                    <ul>
                                                        <li><p><span>{` 当社は、他の情報源から情報を取得し、それを当社のサービスを通じて収集した情報と組み合わせることがあります。例えば、利用者がソーシャル・メディア・サイトを通じてアカウントを作成またはログインした場合、当社は、当該ソーシャル・メディア・サイトが定める承認手続きに従って、利用者の氏名、性別、写真、アカウント情報、友人リストなど、当該サイトの特定の情報にアクセスすることができます。`}</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`収集された情報はどのように利用されるのですか？`}</span></p>
                                                    <ul>
                                                        <li>{`当社は、本サービスの主要機能（移動、ジオロケーションサービスなど）のパフォーマンスを向上するため、またはりアカウントを管理するためなど、当社のサービスを提供、維持、改善するために、収集した情報を使用します。 また、当社は収集した情報を以下の目的で使用します。`}
                                                            <ul>
                                                                <li>{`技術的な通知、アップデート、セキュリティアラート、サポートおよび管理用メッセージを利用者に送信し、利用者のコメント、質問、カスタマーサービスのリクエストに対応する。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`Milesまたは第三者が提供する製品、サービス、オファー、プロモーション、イベントについて利用者に連絡すること、または、利用者が関心を持つと思われる本サービスまたは第三者に関連するニュースや情報を提供すること。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`当社のサービスに関連するトレンド、利用状況、活動をモニタリング、分析する。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`詐欺的な取引やその他の違法行為を検出、調査、防止し、Miles社および利用者やパートナーの権利や資産を保護する。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`本サービスをパーソナライズして改善し、利用者のプロファイルや興味に合った広告、コンテンツ、機能提供する。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`Milesおよび第三者のコンテスト、懸賞、プロモーションを促進し、エントリーや特典を処理して提供する。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`当社の法的義務を果たすため。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`利用規約の施行、または利用規約に関連した使用。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li><p><span>{`情報が収集された時点で利用者に説明されたその他の目的の遂行。`}</span></p></li>
                                                            </ul>
                                        
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>{`収集された情報はどのように共有されるのですか？`}</span></p>
                                                    <ul>
                                                        <li>{`当社は、以下のとおり、または本プライバシーポリシーに記載されているとおり、利用者に関する情報を共有することがあります：`}
                                                            <ul>
                                                                <li>{`当社に代わって業務を遂行するために当該情報へのアクセスを必要とするベンダー、コンサルタント、およびその他のサービスプロバイダーと。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`利用者が本サービスからのログアウトまたはモバイルアプリケーションの削除によって情報の共有を拒否しない限り、第三者のサービスおよび一般的なマーケティング目的のために、匿名化された、集約された、または非識別化された形で、第三者と。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`情報の要求に応じて、適用される法律または法的手続き（国家安全保障または法執行の要件を満たすための公的機関による合法的な要求を含む）に従って、またはそれらによって開示が必要であると当社が判断した場合。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`利用者の行為が当社の利用者同意書やポリシーに反していると当社が判断した場合、またはMilesもしくは他者の権利、資産、安全を守るため。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`合併、会社資産の売却、資金調達、または他社による当社事業の全部または一部の買収に関連して、またはその交渉中に。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`Milesと当社の現在および将来の親会社、関連会社、子会社、および共通の支配と所有下にあるその他の企業との間での使用。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`利用者の同意がある場合、または利用者の指示がある場合。`}</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{`当社は、利用者を特定するために合理的に使用することができない、集約された情報または非識別情報を共有することがあります。`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ]
                    },
                ],
            }
        ].map(flatten),
    }
}.response;


export const RAW_DATA = Object.fromEntries(
    Object.entries(RAW_DATA_2_LEVEL).map(([key, value]) => [key, value])
);


export type ITEM_TYPE = typeof RAW_DATA.user[0];
export type QUESTION_TYPE = typeof RAW_DATA.user[0]['question_array'][0];
